<template>
        <v-container>
            <b-tabs v-if="orderReady" content-class="mt-3">
                <b-tab title="Details" lazy>
                  <b-row class="bg-white rounded">
                    <b-col cols="4">
                      <b-img fluid src="@/assets/images/gallery/power.jpg" class="mt-4" />
                    </b-col>
                    <b-col cols="4">
                      <b-card title="Item Details" sub-title="">
                        <b-card-text><b>Item:</b> {{orderResource.name}}</b-card-text>
                        <b-card-text><b>Order Date:</b> {{orderResource.purchase_price}}</b-card-text>
                      </b-card>
                    </b-col>
                    <b-col cols="4">
                      <b-card class="mb-4 text-center">
                        <img src="@/assets/images/users/7.jpg" class="img-fluid rounded-circle" width="65"/>
                        <h5 class="card-title mt-3 mb-2 pb-1">{{orderUser.full_name}}</h5>
                        <h6 class="card-subtitle text-muted font-weight-normal">
                          {{orderUser.phone}}
                        </h6>

                        <b-button block :href="'mailto:'+orderUser.email" pill variant="danger" class="mt-3 d-flex align-items-center justify-content-center">
                          <feather type="mail" class="feather-sm mr-2"></feather> 
                          {{orderUser.email}}
                        </b-button>
                      </b-card>
                    </b-col>
                    <b-col cols="6">
                      <b-card class="mb-4" bg-variant="info" text-variant="white" title="Offer">
                        <b-card-text><b>Offerred Price:</b> {{orderData.offerred_price}}</b-card-text>
                        <b-card-text><b>Order Date:</b> {{orderData.order_date}}</b-card-text>
                      </b-card>
                    </b-col>
                    <b-col cols="6">
                      <b-card class="mb-4" bg-variant="success" text-variant="white" title="Payment">
                        <b-card-text><b>Payment Method:</b> {{orderData.payment_method}}</b-card-text>
                        <b-card-text><b>Payment Status:</b> {{ orderData.payment_status==="0"? "Pending":"Paid" }}</b-card-text>
                      </b-card>
                    </b-col>
                    <!--<b-col cols="4">
                      <b-card class="mb-4" bg-variant="warning" text-variant="white" title="Comments">
                        <b-card-text><b>Comments:</b> {{orderData.order_comments}}</b-card-text>
                      </b-card>
                    </b-col>-->
                  </b-row>
                </b-tab>

                <b-tab title="Messages" lazy>
                    <div class="bg-white rounded">
                        <RecentChats :order="orderData.id" :type="'order'"></RecentChats>
                    </div>
                </b-tab>

                <b-tab title="Contracts" lazy>
                <div class="bg-white">
                    <div v-if="updating" class="col-12 p-2">
                      <b-row class="border-bottom py-3 align-items-center">
                          <b-col sm="3" class="text-left text-md-right">
                              <label class="fw-medium mb-0">Offerred Price</label>
                          </b-col>
                          <b-col sm="9">
                              <b-form-group><b-form-input  v-model="updateForm.offerred_price" ></b-form-input></b-form-group>
                          </b-col>
                      </b-row>
                      <b-row class="border-bottom py-3 align-items-center">
                          <b-col sm="3" class="text-left text-md-right">
                              <label class="fw-medium mb-0">Order Terms</label>
                          </b-col>
                          <b-col sm="9">
                              <b-form-group><b-form-textarea id="comments" placeholder="Comments" v-model="updateForm.order_comments" rows="3" max-rows="8" no-auto-shrink></b-form-textarea></b-form-group>
                          </b-col>
                      </b-row>
                    </div>
                    <div v-else class="col-12 p-2">
                      <p><b>Final Price:</b> {{ orderData.offerred_price }}</p>
                      <p><b>Order Date:</b> {{ orderData.offerred_price }}</p>
                      <p><b>Contract Terms:</b><br><br>{{ orderData.order_comments }}</p>
                    </div>
                    <div class="col-12 p-2" v-if="parseInt(this.orderData.order_status) < 5">
                      <b-button v-if="updating" class="btn" variant="warning" @click="updateOrder(1)">Update</b-button>
                      <b-button v-else class="btn" variant="warning" @click="enableUpdate()">Revise</b-button>
                      <b-button class="btn ml-2" variant="primary" @click="updateOrder(2)">Accept</b-button>
                      <b-button class="btn ml-2" variant="danger" @click="updateOrder(3)">Reject</b-button>
                    </div>
                </div>
                </b-tab>

                <b-tab title="Invoices" lazy>
                <div v-if="parseInt(this.orderData.order_status) > 1" class="bg-white p-2">
                    <br /><br />
                    <div class="card">
                    <div class="card-body">
                        <div class="container mb-5 mt-3">
                        <div class="container">
                            <div class="row">
                            <div class="col-xl-8">
                                <ul class="list-unstyled">
                                  <li class="text-muted">
                                      To: <span style="color: #5d9fc5">{{ orderUser.full_name }}</span>
                                  </li>
                                  <li class="text-muted">{{ orderUser.email }}</li>
                                  <li class="text-muted">
                                      <i class="fas fa-phone"></i> {{ orderUser.phone }}
                                  </li>
                                </ul>
                            </div>
                            <div class="col-xl-4">
                                <p class="text-muted">Invoice</p>
                                <ul class="list-unstyled">
                                <li class="text-muted">
                                    <i class="fas fa-circle" style="color: #84b0ca"></i>
                                    <span class="fw-bold">ID:</span>{{ orderData.id }}
                                </li>
                                <li class="text-muted">
                                    <i class="fas fa-circle" style="color: #84b0ca"></i>
                                    <span class="fw-bold">Creation Date: </span>{{ orderData.order_date }}
                                </li>
                                <li class="text-muted">
                                    <i class="fas fa-circle" style="color: #84b0ca"></i>
                                    <span class="me-1 fw-bold">Status:</span
                                    ><span class="badge bg-warning text-black fw-bold">
                                    {{ orderData.payment_status==="0"? "Pending":"Paid" }}</span
                                    >
                                </li>
                                </ul>
                            </div>
                            </div>

                            <div class="row my-2 mx-1 justify-content-center">
                            <table class="table table-striped table-borderless">
                                <thead
                                style="background-color: #84b0ca"
                                class="text-white"
                                >
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Qty</th>
                                    <th scope="col">Unit Price</th>
                                    <th scope="col">Amount</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>{{ orderResource.name }}</td>
                                    <td>{{ orderResource.quantity }}</td>
                                    <td>{{ orderData.offerred_price }}</td>
                                    <td>{{ orderData.offerred_price*orderResource.quantity }}</td>
                                </tr>
                                </tbody>
                            </table>
                            </div>
                            <div class="row">
                            <div class="col-xl-8">
                                <p class="ms-3">
                                Add additional notes and payment information
                                </p>
                            </div>
                            <div class="col-xl-3">
                                <ul class="list-unstyled">
                                <li class="text-muted ms-3">
                                    <span class="text-black me-4">SubTotal</span> {{ orderData.offerred_price*orderResource.quantity }}
                                </li>
                                <li class="text-muted ms-3 mt-2">
                                    <span class="text-black me-4">Tax(0%)</span> 0
                                </li>
                                </ul>
                                <p class="text-black float-start">
                                <span class="text-black me-3"> Total Amount</span
                                ><span style="font-size: 25px"> {{ orderData.offerred_price*orderResource.quantity }}</span>
                                </p>
                            </div>
                            </div>
                            <hr />
                            <div class="row">
                            <div class="col-xl-10">
                                <p>Thank you for your purchase</p>
                            </div>
                            <div class="col-xl-2">

                                <span v-if="orderData.order_status === '4'">Payment Requested</span>
                                <span v-if="orderData.order_status === '5'">Payment Complete</span>
                                <button
                                v-if="orderResource.user_id === authId && orderData.payment_status === '0' && orderData.order_status !== '4'"
                                type="button"
                                class="btn btn-primary"
                                @click="updateOrder(4)"
                                >
                                Request Payment
                                </button>
                                <button
                                v-if="orderData.user_id === authId && orderData.payment_status === '0'"
                                type="button"
                                class="btn btn-primary"
                                @click="updateOrder(5)"
                                >
                                Mark As Paid
                                </button>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div v-else class="p-2">No Invoices</div>
                </b-tab>
            </b-tabs>
        </v-container>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import RecentChats from "../../components/chats/RecentChats";
export default {
  data: () => ({
    updateForm: {
      offerred_price: null,
      order_comments: null,
    },
    mainProps: {
          center: false,
          fluidGrow: false,
          blank: true,
          blankColor: '#bbb',
          width: 400,
          height: 400,
          class: ''
    },
    orderData:null,
    orderResource: null,
    orderUser: null,
    updating: false,
    orderReady: false
  }),
  components: {
    RecentChats,
  },
  computed: {
    ...mapState({
      orders: (state) => state.order.orders,
      authId: (state) => state.auth.authId
    }),
    ...mapGetters(["orderById"])
  },
  mounted() {
    this.loadOrder();
  },
  methods: {
    ...mapActions(["getOrder","getResource","updateResource","getUser","changeOrder"]),
    loadOrder(){
      console.log(this.$route.params.id);
      this.getOrder({ id: this.$route.params.id }).then((res) => {
        this.orderData = res[0];
        console.log(this.orderData);
        this.getResource({id: this.orderData.resource_id}).then((ord) => {
          this.orderResource = ord[0];
          console.log(this.orderResource);
          this.getUser({id: this.orderData.user_id}).then((usr) => {
            this.orderUser = usr[0];
            console.log(this.orderUser);
            this.orderReady = true;
          })
        })
      })
    },
    updateOrder(status){
      let nOrder = this.orderData;
      nOrder.order_status = status;
      if(this.updating){
        nOrder.offerred_price = this.updateForm.offerred_price;
        nOrder.order_comments = this.updateForm.order_comments;
      }
      if(status === 5){
        nOrder.payment_status = "1";
        this.getResource({userid: this.orderData.user_id, id: this.orderData.resource_id}).then((res) => {
          let form = res[0];
          form.user_id = this.orderData.user_id;
          this.updateResource(form).then(res => {
            console.log(res, "Resource Assigned to new User");
          })
        })
      }
      this.changeOrder(nOrder).then(res => {
        if(res && 'status' in res && res.status >= 400){
          this.$bvToast.toast(res.data, {
            title: "Something Went Wrong",
            variant: 'danger',
            solid: true
          })
        }
        else{
          this.$bvToast.toast(res.data, {
            title: "Success",
            variant: 'success',
            solid: true
          })
          this.updating = false;
          this.loadOrder();
        }
      })
    },
    enableUpdate(){
      this.updating = true;
      this.updateForm.offerred_price = this.orderData.offerred_price;
      this.updateForm.order_comments = this.orderData.order_comments;
    }
  },
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
}
#container {
  height: 100%;
  width: 100%;
  font-size: 0;
}
#left,
#middle,
#right {
  display: inline-block;
  *display: inline;
  zoom: 1;
  vertical-align: top;
  font-size: 12px;
}
#left {
  width: 50%;
}
#middle {
  width: 100%;
}
#right {
  width: 50%;
}
h4 {
  margin: 2rem 0rem 1rem;
}
#msgWindow {
  margin-top: 20px;
}

#msgs {
  margin: 0px 25px;
  min-height: 200px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: flex-start;
}

.msg {
  margin: 5px 0;
  border: 1px solid silver;
  padding: 3px 7px;
  display: inline-block;
  position: relative;
  border-radius: 10px;
}
.msg::before,
.msg::after {
  content: "";
  display: inline-block;
  bottom: 0;
  position: absolute;
  border: 1px solid silver;
}
.msg::before {
  right: -20px;
  width: 15px;
  height: 15px;
  border-radius: 10px;
}
.msg::after {
  right: -35px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
}
.msg.from {
  align-self: flex-end;
}
.msg.to {
  align-self: flex-start;
}
.msg.to::before {
  right: inherit;
  left: -20px;
}
.msg.to::after {
  right: inherit;
  left: -35px;
}
.msg.typing {
  color: silver;
}

#msgForm input:focus,
#msgForm button:focus {
  box-shadow: none;
}
</style>